.mobile-draft-order-footer {
  width: 100%;
  background-color: white;
  padding: 8px 16px 16px 16px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr auto;

  .mobile-ios-15-warning {
    p {
      display: block;
      font-size: 13px;
      color: var(--warning-color);
      -webkit-text-fill-color: var(--warning-color);
    }
  }

  .mobile-draft-order-footer-content {
    align-self: flex-end;
    display: flex;
    flex-direction: column;

    .mobile-draft-order-total-amount-container {
      border-top: 1px solid #66676C;
      padding: 16px 0;
      align-items: center;

      .draft-order-price {
        justify-content: space-between;
        justify-self: flex-end;
        display: flex;
        flex-direction: row;

        .title {
          font-size: 14px;

          &.total {
            font-weight: bold;
            font-size: 16px;
          }
        }

        .price {
          font-size: 14px;

          &.bold {
            font-weight: bold;
            font-size: 16px;
          }
        }

        .description {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding: 0 40px;
        }
      }
    }
    .mobile-checkout-btn {
      justify-self: flex-end;
      height: 48px;
      width: 100%;
      padding: 0;
      border-radius: 4px;
      max-width: 200px;
      text-transform: capitalize;
      font-weight: 500;
      align-self: flex-end;
    }
  }
}