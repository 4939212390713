.error-chat {
  height: 100%;
  width: 100%;

  &_content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  &_divider {
    align-self: flex-start;
    width: 95%;
    border-top: 1px solid var(--light-font-color);
  }

  &_input {
    border-radius: 0;
    border: 0;
  }

  &_textinput {
    margin-top: 15px;
    width: 90%;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  &_send {
    bottom: 35px;
    position: absolute;
  }

  &_messageitem {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    max-width: 400px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #979797;
    background-color: #F6F6F6;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
