.user-icon {
  border: 1px solid #979797;
  box-sizing: border-box;
  height: 45px;
  width: 45px;
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
  vertical-align: middle;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.15s color, background;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
  background: white;

  &:after {
    border-radius: 50%;
    border: 4px solid white;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
  }

  .avatar {
      object-fit: cover;
      height: 58px;
      width: 58px;
      overflow: hidden;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
  }
}
