.price-container {
  display: flex;

  &.long-price {
    h4 {
      font-size: 10px;
      font-weight: 500;
    }
  }

  :nth-child(1) {
    font-size: 14px;
    font-weight: 600;
  }
  :nth-child(2) {
    color: rgba(0, 0, 0, 0.4);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
  }
}
