.product-details-wrapper {
    display: grid;
    grid-template-columns: 80px 1fr auto;
    width: 100%;
    gap: 0 3%;

    .image-container {
        position: relative;
        display: block;

        img {
            object-fit: contain;
            border-radius: var(--border-radius-s);
            width: 100%;
        }
    }

    .product-card-details-container {
        display: grid;
        gap: 8px;
        width: 100%;
        align-content: flex-start;
        justify-content: flex-start;
        height: 100%;

        .product-description {
            font-size: 12px;
            line-height: 18px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        h5 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-align: left;
        }

        .price-container {
            h4 {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
            }

            :nth-child(1) {
                color: var(--main-color);
                -webkit-text-fill-color: var(--main-color);
                margin-right: 8px;
            }

            :nth-child(2) {
                color: rgba(0, 0, 0, 0.4);
                -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
                text-decoration: line-through;
            }
        }

        span {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.64);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
            height: 28px;
        }
    }

    .added-to-showroom-msg {
        font-size: 12px;
        align-self: center;
    }

    .filled-button {
        height: 28px;
        font-size: 12px;
        align-self: center;
        width: auto;
        min-width: auto;
        white-space: nowrap;
    }

    .add-to-showroom-button-group {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}
