.intro-content-wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 50%;

    .video-controls-container {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 auto;
        margin: 0 24px;

        &.no-logo {
            padding-top: 2em;
        }
        
        .prev-control-container {
            order: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .prev-story {
                padding: 6px;
                display: flex;
                margin-top: -2em;
                align-items: center;
                border-radius: 50%;
                color: #ffffff;
                -webkit-text-fill-color: #ffffff;
                background-color: #00000028;
                touch-action: manipulation;

                &:disabled {
                    opacity: .2;
                    cursor: pointer;
                }
            }
        }

        .next-control-container {
            order: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            .next-story {
                padding: 6px;
                display: flex;
                margin-top: -2em;
                align-items: center;
                border-radius: 50%;
                color: #ffffff;
                -webkit-text-fill-color: #ffffff;
                background-color: #00000028;
                touch-action: manipulation;
    
                &:disabled {
                    opacity: .2;
                    cursor: pointer;
                }
            }
        }

        .state-observers {
            order: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            -webkit-text-fill-color: #fff;
            flex: 1;
            flex-flow: column;
            height: 100%;
            margin-top: -1em;

            .video-loader-container {
            }
        }
    }

    .welcome-container{
        display: flex;
        flex-flow: column;
        width: 100%;
        animation: showWelcomeMessage 2s;
    }
}

@keyframes showWelcomeMessage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
