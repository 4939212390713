.avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -15px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
    background: white;
    opacity: 0;
    animation: animateImageOnload 1s .5s cubic-bezier(0,1,.6,1) forwards;
    animation-play-state: paused;

    &.size-md {
        height: 60px;
        width: 60px;

        .avatar {
            height: 58px;
            width: 58px;
        }
    }

    &.size-sm {
        height: 50px;
        width: 50px;

        .avatar {
            height: 48px;
            width: 48px;
        }
    }

    &:after {
        border-radius: 50%;
        border: 4px solid white;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
    }

    .avatar {
        object-fit: cover;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
    }

    &.loaded {
        animation-play-state: running;
    }

    &.initials {
        animation: animateImageOnload 1s .5s cubic-bezier(0,1,.6,1) forwards;
        animation-play-state: running;
    }
}

@media (max-width: 480px) {
    .avatar-container {
        &.size-md {
            height: 50px;
            width: 50px;

            .avatar {
                height: 48px;
                width: 48px;
                font-size: 16px;
            }
        }
    
        &.size-sm {
            height: 50px;
            width: 50px;

            .avatar {
                height: 48px;
                width: 48px;
                font-size: 16px;
            }
        }

        &:after {
            border: 3px solid white;
        }

        .avatar {
            height: 48px;
            width: 48px;
            font-size: 16px;
        }
    }
}

@keyframes animateImageOnload {
    0% {
        opacity: 0;
        transform: scale(.5);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}
