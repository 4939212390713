.message-item {
  display: -webkit-box;
  flex-direction: row;
  align-items: flex-start;

  .hide-separator {
    display: none;
  }

  &_hidden-message {
    display: none;
  }

  &_start-call {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    display: flex;

    p {
      opacity: 0.84;
      color: #000000;
      font-family: var(--font-family);
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
      margin: 0 10px;
      cursor: pointer;
    }

    &_question {
      opacity: 0.64 !important;
      cursor: default !important;
      color: #000000;
      font-weight: 500 !important;
      margin-right: 10px;
    }

    &_info {
      margin-top: 10px;
      margin-left: 5px;
    }
  }

  &_system {
    align-items: center;
    justify-content: center;
    color: var(--gray-color);
    -webkit-text-fill-color: var(--gray-color);
    font-size: 16px;
    display: flex;

    &_text {
      flex: 2;
      display: contents;
      color: var(--gray-color);
      -webkit-text-fill-color: var(--gray-color);
    }

    &_delimiter {
      flex: 1;
      height: 1px;
      background-color: var(--gray-color);
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &_typing {
    align-items: center;
  }

  &_my {
    margin-left: auto;

    .message-item_start-call {
      flex-direction: row;
    }
  }

  &_circle {
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: 1px solid var(--main-color);
    border-radius: 24px;
    margin-top: 10px;
  }

  &_info {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;

    &_my {
      align-items: flex-end;
      width: 100%;
    }

    &_time {
      margin-left: 15px;
      margin-top: 5px;
      height: 20px;
      width: 69px;
      opacity: 0.32;
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;

      &_my {
        display: flex;
        justify-content: flex-end;
      }
    }

    &_message_typing {
      margin-left: 15px;
      font-weight: 600;
      color: var(--main-color);
      -webkit-text-fill-color: var(--main-color);
      flex-direction: row;
      display: flex;
    }

    &_message-image {
      max-width: 170px;
      border: var(--main-color) solid 4px;
      border-radius: 8px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 4px;
      }
    }

    &_message-text {
      padding: 10px 15px;
      max-width: 250px;
      border-radius: 8px;

      &_opponent {
        box-sizing: border-box;
        border: 1px solid #979797;
        background-color: #F6F6F6;
        margin-left: 15px;
        white-space: pre-wrap;
        word-break: break-word;
        a {
          color: #000000;
        }
      }

      &_my {
        background-color: var(--main-color);
        color: white;
        -webkit-text-fill-color: #ffffff;
        white-space: pre-wrap;
        word-break: break-word;
        a {
          color: white;
          -webkit-text-fill-color: #ffffff;
        }
      }
    }
  }
}
