.close-button-container {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;

    &.invert-button {

    }

    .close-button {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: white;
            -webkit-text-fill-color: white;
        }
    }
}

