.welcome-message-container-wrapper {
    margin: 1em 3em 1em 3em;
    overflow: hidden;
    overflow-y: auto;
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .welcome-message-container {
        display: flex;
        flex-flow: column;

        .primary-text {}

        .secondary-text {
            margin-top: 2ch;
        }
    }
}

@media (max-width: 479px) {
    .welcome-message-container-wrapper {
        margin: 1em 1em 1em 1em;

        .welcome-message-container {

            .secondary-text {
                margin-top: 1.5ch;
            }
        }
    }
}
