.video-container-wrapper {
    position: relative;
    border-radius: 0 !important;
    height: 100%;
    min-height: 180px;
    flex: 10 1 100%;
    width: 100%;
    z-index: 10;

    &.small-preview {
        .video-container {
            .video-element-wrapper {
                animation: scaleVideoPreview .8s forwards;
                overflow: hidden;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
                transform: scale(.5) translate(70px, 120px);
                width: 50%;
                border-radius: 20px;
            }
        }
    }

    .video-container {
        height: 100%;
        width: 100%;
        min-height: 180px;

        .video-element-wrapper {
            height: 100%;
            width: 100%;
            position: absolute;
            transform-origin: left top;
            transition: .25s ease-in-out transform;
            will-change: transform;

            &:after {
                content: '';
                position: absolute;
                background-color: #f0f0f0;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    animation: video-element-fade-in .5s forwards;
}

.av-alert-message {
    display: flex;
    color: #00000094;
    -webkit-text-fill-color: #00000094;
    line-height: 26px;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 32px;
    text-align: center;
    background-color: #F0F0F0A8;
}

.video-container {
    position: relative;
    height: 100%;
    width: 100%;

    .back-btn {
        position: absolute;
        top: 24px;
        left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        z-index: 100;
        color: #ffffffa3;
        -webkit-text-fill-color: #ffffffa3;
        height: 32px;
        width: 32px;
        line-height: 28px;
        text-align: center;
        vertical-align: middle;
        background-color: #00000028;
        border-radius: 50%;
        transition: 0.15s color, background;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.preview-controls {
    position: absolute;
    bottom: 24px;
    left: 32px;
    width: 144px;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    transform-origin: left bottom;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
        pointer-events: all;
        transition: .2s opacity, .25s transform, .25s left;
    }

    button {
        border-radius: 50%;
        color: #ffffff;
        -webkit-text-fill-color: #ffffff;
        border: 1px solid #ffffff;
        background-color: rgba(0, 0, 0, 0.16);
        height: 65px;
        width: 65px;
        outline: none;
        position: relative;

        svg {
            font-size: 42px;
            vertical-align: middle;
        }
    }
}

.waiting-room_control-position {
    top: 0;
    height: fit-content;
}

.video-button-loader {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: var(--main-color);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    filter: blur(2px);

    &.animated {
        animation: caazam-video-loader-animation 1s ease-in-out infinite backwards;
    }
}

@keyframes caazam-video-loader-animation {
    25% {
        opacity: 0;
    }

    50% {
        opacity: .24;
    }

    75% {
        opacity: 0;
    }
}

@keyframes video-element-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleVideoPreview {
    0% {
        width: 100%;
        border-radius: 0;
        transform: scale(1) translate(0, 0);
    }

    60% {
        transform: scale(1) translate(0, 0);
    }

    70% {
        width: 50%;
    }

    100% {
        transform: scale(.5) translate(70px, 120px);
        width: 50%;
        border-radius: 20px;
    }
}
