.expanded-product-preview {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  outline: 0.0625rem solid transparent;
  border-radius: 0.5rem;
  padding: 6px;
  z-index: 1;
  max-height: calc(var(--product-image-height) + 12px);
  width: calc(100% - 50px);
  display: flex;
  flex-flow: row;
}

.story-product-image-wrapper {
  max-height: var(--product-image-height);
  max-width: var(--product-image-width);
  display: flex;
  align-items: center;
  justify-content: center;

  &.collapsed {
    max-height: calc(var(--product-image-height) - 10px);
    max-width: calc(var(--product-image-width) - 10px);
  }
}

.story-product-empty-image-wrapper {
  height: var(--product-image-height);
  width: calc(var(--product-image-width) - 10px);
  border: lightgrey solid 1px;
  border-radius: 6px;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  text-align: center;
}

.collapsed-product-preview {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  outline: 0.0625rem solid transparent;
  border-radius: 0.5rem;
  padding: 6px;
  z-index: 1;
  max-height: calc(var(--product-image-height) + 12px);
  width: calc(var(--product-image-width) + 10px);
  align-items: center;
  display: flex;
  flex-flow: column;
  transition: width .15s;

  &:hover {
    width: calc(100% - 50px);
    flex-flow: row;
    align-items: flex-start;
    z-index: 101;

    .story-product-image-wrapper.collapsed {
      max-height: var(--product-image-height);
      max-width: var(--product-image-width);
    }
  }


  .product-preview-price-container {

  }
}

.product-preview-description-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  max-height: 90px;
  margin-left: 6px;

  .product-preview-description-wrapper {
    display: flex;
    flex-flow: column;
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
  }

  &.action {
    grid-template-columns: auto 90px;

    p {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }

  .product-preview-action-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .product-preview-action {
      min-height: 1.75rem;
      padding: 6px;
      position: relative;
      background-color: var(--p-action-primary);
      box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 2.25rem;
      margin: 0;
      cursor: pointer;
      line-height: 1;
      text-align: center;
      border-radius: 0.25rem;
      border: 0.06rem solid rgb(186, 191, 195);
      user-select: none;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;

      span {
        width: 100%;
        -webkit-text-fill-color: white;
        color: white;
        font-size: 12px;
        font-weight: 500;
        line-height: 1rem;
        text-transform: initial;
        letter-spacing: initial;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 0.0625rem;
        min-height: 0.0625rem;
      }
    }
  }
}
