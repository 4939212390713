$icon-color: #B5B5B5;

.mobile-search-input {
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    background-color: #f3f3f4;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0 0 0 8px;

    svg {
        font-size: 1.2rem;
        color: $icon-color;
        -webkit-text-fill-color: $icon-color;
    }

    .mobile-search-field {
        padding: 7px 8px 7px 4px;
        width: 100%;
        text-align: start;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #B5B5B5;
            -webkit-text-fill-color: #B5B5B5;
            opacity: 1; /* Firefox */
          }
    }

    .mobile-search-field[type=search] { -webkit-appearance: none; }
    /* clears the ‘X’ from Internet Explorer */
    .mobile-search-field[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    .mobile-search-field[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    .mobile-search-field[type="search"]::-webkit-search-decoration,
    .mobile-search-field[type="search"]::-webkit-search-cancel-button,
    .mobile-search-field[type="search"]::-webkit-search-results-button,
    .mobile-search-field[type="search"]::-webkit-search-results-decoration { display: none; }

    .mobile-clear-search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 8px;
        opacity: 0;
        animation: fadeIn .4s forwards;

        svg {
            border-radius: 50%;
            font-size: 14px;
            padding: 0;
            height: 16px;
            width: 16px;
            background-color: #B5B5B5;
            color: #fff;
            -webkit-text-fill-color: #fff;
        }
    }

    .loader-wrapper {
        position: absolute;
        right: 0;
        height: auto;
        padding: 8px;

        .loader {
            height: 14px;
            width: 14px;
            border-width: 1px;
            border: 1px solid #f3f3f4;
            border-right-color: $icon-color;
            border-bottom-color: $icon-color;
            border-top-color: $icon-color;
            margin: 0;
            animation: spin .4s ease-in infinite;
        }
    }
}
