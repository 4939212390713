$gap: .5px;

:root {
    --font-color: var(--base-font-color);
    --font-size: 12px;
}

.multiple-host-avatar-container {
    height: 112px;
    width: 112px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    grid-column: 1;
    grid-row: 1 / 3;
    justify-content: space-between;
    align-content: space-between;
    overflow: hidden;
    border-radius: 6px;

    .host-avatar-container-wrapper {
        display: flex; 
        justify-content: center; 
        align-items: center; 
        width: 100%;
        height: 100%;
        position: relative;
        color: var(--font-color);
        -webkit-text-fill-color: var(--font-color);

        &:nth-child(1) {
            grid-row: 1;
            grid-column: 1;
        }

        &:nth-child(2) {
            grid-row: 2;
            grid-column: 2;
        }
        &:nth-child(3) {
            grid-row: 1;
            grid-column: 2;
        }
        &:nth-child(4) {
            grid-row: 2;
            grid-column: 1;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgb(229, 229, 229);
        }

        .host-avatar {
            object-fit: cover;
            height: 100%;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            background-color: #f6f6f6;
            opacity: 0;
           
            &.loaded {
                animation: onenter-list-item-animation .2s forwards;
            }

            &.empty {
                background-color: rgb(229, 229, 229);
            }

            &.initials {
                overflow: hidden;
                border-radius: 50%;
                height: calc(100% - 8px);
                width: calc(100% - 8px);
                opacity: 0;
                animation: onenter-list-item-animation .2s forwards;
            }
        }
    }
}

@media (max-width: 479px) {
    .multiple-host-avatar-container {
        height: 80px;
        width: 80px;

        .host-avatar-container-wrapper {
            .host-avatar {
                font-size: 14px;
            }
        }
    }
}
