.loading-style {
  padding: 2px;
  width: 200px;
  height: 100px;
}

.image-placeholder {
  border-radius: 10px;
  width: 100%;
  height: 100%;

  div {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px !important;
  }
}
