.empty-image {
	border: #F5F5F5 solid 1px;
	border-radius: var(--border-radius-s);
	background-color: rgba(118, 118, 128, 0.09);
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	text-align: center;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	p {
		width: 80%;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	&.s-size {
		height: 120px;
		width: 80px;
	}

	&.xl-size {
		height: 150px;
		width: 150px;
	}

	.camera-alt-icon {
		&.s-size {
			width: 25px;
			height: 25px;
		}

		&.m-size {
			width: 40px;
			height: 40px;
		}

		&.l-size {
			width: 50px;
			height: 50px;
		}

		&.xl-size {
			width: 75px;
			height: 75px;
		}
	}

	&.mobile {
		width: calc(100% - 2px);
		height: calc(100% - 2px);

		&.s-size {
			height: 90px;
			width: 58px;
		}

		&.xl-size {
			height: 120px;
			width: 120px;
			margin-left: 39px;
			margin-bottom: 12px;
		}

		.camera-alt-icon {
			color: #3F3F3F;
			-webkit-text-fill-color: #3F3F3F;

			&.s-size {
				width: 25px;
				height: 25px;
			}

			&.m-size {
				width: 30px;
				height: 30px;
			}

			&.l-size {
				width: 45px;
				height: 45px;
			}

			&.xl-size {
				width: 55px;
				height: 55px;
			}
		}
	}
}
