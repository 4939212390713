#product-alert {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;

	.mobile-dialog {
		left: 0;
		right: 0;
		height: 100vh;
		position: absolute;
		outline: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
		z-index: 9999;
		transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

		.mobile-alert {
			opacity: 1;
			transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			height: 100%;
			outline: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.mobile-alert-dialog {
				width: 100%;
				border-radius: var(--border-radius-s) !important;
				background-color: #ffffff !important;
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08) !important;
				max-width: 600px;
				display: flex;
				max-height: calc(100% - 64px);
				flex-direction: column;
				margin: 32px;
				position: relative;
				overflow-y: auto;
				color: rgba(0, 0, 0, 0.87);
				transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)0ms;

				#mobile-alert-dialog-slide-title {
					text-align: center;
					flex: 0 0 auto;
					margin: 0;
					padding: 16px 24px;
					color: rgba(0, 0, 0, 0.87);
					h2 {
						font-family: var(--font-family);
						color: #000000;
						font-size: 16px;
						font-weight: 500;
						letter-spacing: 0;
						line-height: 23px;
						margin: 0;
					}
				}

				.dialog-content__root {
					text-align: center;
					flex: 1 1 auto;
					padding: 8px 24px;
					overflow-y: auto;
					p {
						font-family: var(--font-family);
						opacity: 0.64;
						color: #000000;
						font-size: 12px;
						letter-spacing: 0;
						line-height: 18px;
						text-align: center;
						font-weight: 400;
						margin: 0 0 12px;
					}
				}

				.dialog-actions__root {
					flex: 0 0 auto;
					display: flex;
					padding: 8px;
					align-items: center;
					justify-content: center;
				}

				.mobile-accept-button {
					height: 32px;
					width: 120px;
					border-radius: 22px;
					background-color: #000000;
					color: #ffffff;
					font-family: var(--font-family);;
					font-size: 14px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 20px;
					text-align: center;
					margin-bottom: 8px;
				}
			}
		}
	}
}
