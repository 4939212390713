.chat-header {
  &_container {
    background-color: var(--main-color);
	  height: 80px;
	  flex-direction: row;
	  align-items: center;
	  justify-content: flex-start;
	  display: flex;

	  .back-btn {
	  	position: absolute;
	  	top: 24px;
		left: 12px;
	  	display: flex;
	  	justify-content: center;
	  	align-items: center;
	  	font-size: 24px;
	  	cursor: pointer;
	  	z-index: 100;
	  	color: #00000070;
	  	-webkit-text-fill-color: #00000070;
	  	height: 32px;
	  	width: 32px;
	  	line-height: 28px;
	  	text-align: center;
	  	vertical-align: middle;
	  	background-color: #F5F5F5;
	  	border-radius: 50%;
	  	transition: 0.15s color, background;
	  }
		.back-btn-mobile {
	  	position: absolute;
	  	top: 24px;
			left: 5px;
	  	display: flex;
	  	justify-content: center;
	  	align-items: center;
	  	font-size: 24px;
	  	cursor: pointer;
	  	z-index: 100;
	  	color: #00000070;
	  	-webkit-text-fill-color: #00000070;
	  	height: 32px;
	  	width: 32px;
	  	line-height: 28px;
	  	text-align: center;
	  	vertical-align: middle;
		background-color: #F5F5F5;
	  	border-radius: 50%;
	  	transition: 0.15s color, background;
	  }

	  .video-btn {
		  position: absolute;
		  top: 20px;
		  right: 65px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-size: 24px;
		  cursor: pointer;
		  z-index: 100;
		  color: #00000070;
		  -webkit-text-fill-color: #00000070;
		  height: 38px;
		  width: 38px;
		  line-height: 28px;
		  text-align: center;
		  vertical-align: middle;
		  background-color: #F5F5F5;
		  border-radius: 50%;
		  transition: 0.15s color, background;
	  }

	  .start-video-call__icon {
		  margin-right: 4px;
		  margin-bottom: 4px;
	  }
	  .video-btn-mobile {
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  font-size: 24px;
		  cursor: pointer;
		  z-index: 100;
		  color: #00000066;
		  -webkit-text-fill-color: #00000066;
		  height: 32px;
		  width: 32px;
		  line-height: 28px;
		  text-align: center;
		  vertical-align: middle;
		  background-color: #F5F5F5;
		  border-radius: 50%;
		  position: absolute;
		  right: 60px;
		  transition: .2s color, .2s background;
	  }
  }

	&_video-container {
    background-color: var(--main-color);
		border-top-left-radius: var(--app-border-radius);
		border-top-right-radius: var(--app-border-radius);
	  height: 80px;
	  flex-direction: row;
	  align-items: center;
	  justify-content: flex-start;
	  display: flex;

		.close-button {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			cursor: pointer;
			z-index: 100;
			color: #00000066;
			-webkit-text-fill-color: #00000066;
			height: 32px;
			width: 32px;
			line-height: 28px;
			text-align: center;
			vertical-align: middle;
			background-color: #ffffffa3;
			border-radius: 50%;
			position: absolute;
			right: 12px;
			transition: 0.15s color, background;
		}
		.close-button-mobile {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			cursor: pointer;
			z-index: 100;
			color: #00000066;
			-webkit-text-fill-color: #00000066;
			height: 32px;
			width: 32px;
			line-height: 28px;
			text-align: center;
			vertical-align: middle;
			background-color: #ffffffa3;
			border-radius: 50%;
			position: absolute;
			right: 5px;
			transition: 0.15s color, background;
		}
	}

  &_title {
		&_host {
			color: #ffffff;
			-webkit-text-fill-color: #ffffff;
			font-family: var(--font-family);
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 29px;
			margin-left: 10px;
			text-align: center;
			max-width: 50%;
		}
		&_room {
			color: #ffffff;
			-webkit-text-fill-color: #ffffff;
			font-family: var(--font-family);
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 29px;
			margin-left: 10px;
			text-align: center;
			max-width: 40%;
		}
  }

	&_title-error {
	  color: #ffffff;
	  -webkit-text-fill-color: #ffffff;
	  font-family: var(--font-family);
	  font-size: 18px;
	  font-weight: 500;
	  letter-spacing: 0;
	  line-height: 29px;
	  text-align: center;
	  max-width: 70%;
  }

	&_avatars {
		max-width: 40%;
	}

  &_menu_btn {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  font-size: 24px;
	  cursor: pointer;
	  z-index: 100;
	  color: #000000;
	  -webkit-text-fill-color: #000000;
	  height: 32px;
	  width: 32px;
	  line-height: 28px;
	  text-align: center;
	  vertical-align: middle;
	  background-color: #ffffffa3;
	  border-radius: 50%;
	  position: absolute;
	  transition: 0.15s color, background;
		right: 60px;
	  top: 24px;

	  &_dots-icon {
		  opacity: 0.4;
	  }
  }
}

.left-mobile-position {
	left: 5px;
}

.left-position {
	left: 12px;
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.short-title {
	width: 100px;
}
