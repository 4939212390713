.welcome-chat {
  width: 100%;
  height: 100%;

  &_content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }

  &_divider {
    align-self: flex-start;
    width: 95%;
    border-top: 1px solid var(--light-font-color);
  }

  &_buttons {
    bottom: 35px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  &_input {
    border-radius: 0;
    border: 0;
  }

  &_error {
    color: red;
    -webkit-text-fill-color: red;
  }
}
