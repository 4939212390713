.mobile-cart-footer {
    width: 100%;
    background-color: white;
    padding: 8px 16px 16px 16px;
    display: grid;
    grid-auto-flow: row;
	grid-template-rows: 1fr auto;

	.mobile-discount-code-input-wrapper {
		justify-self: flex-start;
		align-self: flex-start;
	}

	.mobile-ios-15-warning {
		p {
			display: block;
			font-size: 13px;
			color: var(--warning-color);
			-webkit-text-fill-color: var(--warning-color);
		}
	}

    .mobile-cart-footer-content {
        align-self: flex-end;

		.mobile-cart-total-amount-container {
			border-top: 1px solid #66676C;
			display: grid;
			grid-auto-flow: column;
			gap: 0 16px;
			padding: 16px 0 0 0;
			align-items: center;
			grid-template-columns: auto 1fr;

			.mobile-total-amount-title {
				grid-row: 1;
				grid-column: 1;
				font-size: 14px;
  				font-weight: 600;
			}

			.mobile-total-products-in-cart {
				grid-row: 2;
				grid-column: 1;
				font-size: 14px;
			}

			.mobile-checkout-btn {
				grid-row: 1/3;
				justify-self: flex-end;
				height: 48px;
				width: 100%;
				padding: 0;
				border-radius: 4px;
				max-width: 200px;
				text-transform: capitalize;
				font-weight: 500;
			}
		}
    }
}
