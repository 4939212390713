.loader-container-wrapper {
    padding: 0 3rem;
    width: 100%;
    display: grid;
    align-items: flex-start;

    h3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin: 1rem 0 0 .6ch;
        align-self: flex-start;
    }
}

.form-container-wrapper {
    width: 100%;
}

@media (max-width: 480px) {
    .form-container-wrapper {
        padding-bottom: 2rem;
    }
}

@media (max-width: 479px) {
    .loader-container-wrapper {
        padding: 0 1em;
    }
}
