.intro-page-header-container {
    display: grid;

    .host-avatar-section {
        min-height: 50px;

        &.no-logo {
            width: 100%;
            padding: 4em 4em 0 4em;
        }

        .shop-avatars-wrapper {
            animation: showHostAvatar 2s;
            opacity: 1;
            transition: 2s opacity;

            &.no-logo {
                position: relative;
                display: block;
                padding-top: 20%;
                position: relative;
                width: 100%;
                height: 0;

                .shop-avatars-content {
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &.hidden {
                animation: showHostAvatar 0s;
                opacity: 0;
                transition: 0s;
            }
        }
    }
}

@keyframes showHostAvatar {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
