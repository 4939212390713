.messenger {
  &_container {
    height: 100%;
    width: 100%;
    display: block;
    box-shadow: 0px 4px 10px var(--light-font-color);
    border-radius: var(--app-border-radius);

    &_typing {
      position: absolute;
      bottom: 120px;
      margin-left: 20px;
    }
  }

  &_controls {
    bottom: 0;
    height: 110px;
    width: 100%;
    border-top: 1px solid var(--light-font-color);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: white;
    box-shadow: inset 0 -4px 0 0 var(--main-color);

    &_client_radius-styles {
      border-bottom-left-radius: var(--app-border-radius);
      border-bottom-right-radius: var(--app-border-radius);
    }

    &_file-input {
      display: none;
    }

    &_input {
      margin-top: 10px;
      width: 100%;
      margin-bottom: 5px;
      border-radius: var(--border-radius-xs);
      border: 1px solid var(--light-font-color);
      padding: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }

    &_send {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      margin-left: 10px;
      border-radius: var(--border-radius-xs);
      background-color: var(--main-color);
      margin-right: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: white;
      }
    }

    &_send-image {
      border: 1px solid var(--main-color);
      border-radius: var(--border-radius-xs);
      min-height: 40px;
      min-width: 40px;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      svg {
        fill: var(--main-color);
      }
    }
  }

}
