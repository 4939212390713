html,
input,
button,
p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea {
	all: unset;
}

p {
	line-height: 1.6em;
}

.App-default {
	border-radius: 0;
	background-color: transparent;
}

#root {
	height: 100%;
	font-size: 1rem;
	display: flex;
}

html {
	font-size: 16px;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	/* --main-color: #08b273; */
	--main-color: #1c1b1b;
	--main-color-hover: #9d9d9d;
	--error-color: #d83a68;
	--warning-color: #ffaa15;
	--base-font-color: rgba(0, 0, 0, 0.64);
	--light-font-color: rgba(0, 0, 0, 0.32);
	--border-radius-xs: 0;
	--border-radius-s: 0;
	--border-radius-m: 0;
	--boutiq-border-radius-m: 6px;
	--border-radius-l: 0;
	--app-border-radius: 8px;
	--app-header-radius: 0px;
	--input-border-radius: 4px;
	--font-family: 'Poppins', sans-serif;
	--gray-color: #808080;
	--btn-text-style: capitalize;  /* or uppercase */
}
html.round {
	--border-radius-xs: 8px;
	--border-radius-s: 16px;
	--border-radius-m: 28px;
	--boutiq-border-radius-m: 28px;
	--border-radius-l: 32px;
	--app-border-radius: 32px;
	--app-header-radius: 32px;
	--input-border-radius: 32px;
}

body {
	margin: 0;
	height: 100%;
	width: 100%;
	position: fixed;
	overflow: hidden;
	overscroll-behavior-y: none;
}

button {
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
}

button:disabled {
	cursor: not-allowed;
}

.no-outline:focus {
	outline: none;
  }
  
