.product-details-container {
	height: 100%;
	padding: 24px 5%;
	display: grid;
	grid-row-gap: 16px;
    grid-template-rows: 40px 1fr;

	.select-block {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		position: relative;
		background-color: white;

		.select-arrow-block {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			background-color: white;
			padding-right: 10px;
			border-top-right-radius: var(--border-radius-xs);
			border-bottom-right-radius: var(--border-radius-xs);
			right: 0;
			position: absolute;
			height: 100%;
		}

		.select-delimiter {
			width: 2px;
			height: 25px;
			background-color: rgba(0,0,0,0.4);
			margin-right: 10px;
			margin-left: 10px;
		}
	}

	img {
		border-radius: var(--border-radius-s);
	}

	.back-button {
		button {
			border: 1px solid #cfc8bb;
			color: #cfc8bb;
			-webkit-text-fill-color: #cfc8bb;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.back-chat {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: 1fr auto;
			align-items: center;
			width: 100%;
			gap: 10px;
		}
	}

	form {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 8px;
		select {
			outline: none;
			width: 100%;
			-webkit-appearance: none;
			appearance: none;
			font-size: 16px;
			border: 1px solid #ccc3bd;
			border-radius: var(--border-radius-xs);
			padding: 14px 45px 14px 12px;
			line-height: 20px;
			background-color: transparent;
			z-index: 100;
		}

		select:focus {
			border: solid 1px var(--main-color);
		}

		.product-sidebar-select {
			&__control {
				box-sizing: border-box;
				border: 1px solid #ccc3bd;
				border-radius: var(--border-radius-m);
				height: 40px;
				font-size: 14px;
			}
			&__menu {
				border-radius: var(--border-radius-xs);
			}
		}
	}
	.full-size-image {
		position: absolute;
		width: 100%;
		height: calc(100% - 58px);
		right: 0;
		background: white;
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.carousel-root {
			width: 90%;
			.carousel {
				.slider-wrapper {
					max-height: 650px;
					border-radius: var(--border-radius-s);
				}
				.slide {
					background: unset;
				}
				.control-next.control-arrow {
					border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;

					&:before {
						border-left: 8px solid black;
					}
				}
				.control-prev.control-arrow {
					border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);

					&:before {
						border-right: 8px solid black;
					}
				}
				.carousel-status {
					visibility: hidden;
				}
			}
		}
		.image-container {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: -24px;
		}
		> div:first-child {
			width: 90%;
			display: flex;
			justify-content: flex-end;
			span {
				font-size: 24px;
				cursor: pointer;
				@media (max-width: 767px) {
					right: unset;
					left: 16px;
					font-size: 16px;
					text-decoration: underline;
					margin-bottom: 30px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		&.in-showroom {
			grid-template-rows: 40px 220px auto;
		}
		.product-details {
			justify-content: unset;
		}
	}

	@media (max-width: 767px) {
		.gallery-wrapper {
			height: calc(100vh - 124px);
			position: relative;
			overflow: scroll;
		}
		.full-size-image {
			justify-content: flex-start;
			div:first-child {
				justify-content: flex-start;
				span {
					right: unset;
					left: 16px;
					font-size: 16px;
					text-decoration: underline;
					margin-bottom: 30px;
				}
			}
		}
	}
}
