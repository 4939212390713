.full-screen-product-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .presentation-view {
        box-sizing: border-box;
        height: 100%;
        width: 60%;
        min-width: 550px;
        border: 1px solid #979797;
        border-radius: 8px;
        background-color: rgba(0,0,0,0.8);
        position: absolute;
        right: 0;
        z-index: 101;
        padding: 32px;
        display: flex;
        flex-direction: row;

        &_details {
            width: 35%;
            max-width: 450px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 160px;
            padding-left: 32px;
            padding-right: 16px;

            &_added {
                color: #FFFFFF;
                -webkit-text-fill-color: #FFFFFF;
                font-family: var(--font-family);
                font-size: 17px;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
                margin-bottom: 34px;
                width: fit-content;
                text-transform: uppercase;
                margin-top: 8px;
            }

            &_mode-btn {
                height: 40px !important;
                width: 232px !important;
                border-radius: 100px !important;
                background-color: #08B273 !important;
                margin-bottom: 40px !important;
                display: flex;
                justify-content: center;

                &_active {
                    background-color: transparent !important;
                    border: #08B273 2px solid !important;
                }

                &_content {
                    color: #FFFFFF;
                    -webkit-text-fill-color: #FFFFFF;
                    font-family: var(--font-family);
                    font-size: 17px;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                    display: flex;
                    align-items: center;

                    &_active {
                        color: #08B273;
                        -webkit-text-fill-color: #08B273;
                    }

                    svg {
                        margin-right: 5px;
                    }
                }
            }

            &_prices {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                &_current-price {
                    color: #08B273;
                    -webkit-text-fill-color: #08B273;
                    font-family: var(--font-family);
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 29px;
                }
                &_full_price {
                    opacity: 0.4;
                    color: #FFFFFF;
                    -webkit-text-fill-color: #FFFFFF;
                    font-family: var(--font-family);
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 29px;
                }

                .price-container {
                    :nth-child(2) {
                        color: rgba(255, 255, 255, 0.4);
                        -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
                    }
                }
            }

            &_title {
                color: #FFFFFF;
                -webkit-text-fill-color: #FFFFFF;
                font-family: var(--font-family);
                font-size: 21px;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 10px;
            }

            &_showroom-btn {
                height: 40px !important;
                width: 160px !important;
                border-radius: 100px !important;
                background-color: #000000 !important;
                margin-bottom: 26px !important;
                display: flex;
                justify-content: center;
                &_content {
                    color: #FFFFFF;
                    -webkit-text-fill-color: #FFFFFF;
                    font-family: var(--font-family);
                    font-size: 17px;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                }
            }

            &_description {
                font-family: var(--font-family);
                opacity: 0.64;
                color: #FFFFFF;
                -webkit-text-fill-color: #FFFFFF;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 23px;
                height: 60%;
                overflow-y: auto;
                max-width: 85%;

                & * {
                    all: revert;
                }
            }
        }

        &_gallery {
            min-width: 300px;
            max-width: 40%;
            align-items: center;
            margin-left: 40px;
            margin-right: 40px;
            display: flex;
            flex-direction: column;
            flex: 2;
            height: 100%;
            margin-bottom: 30px;

            &_dots {
                display: flex;
                justify-content: center;
            }

            &_list {
                margin-top: 42px;
            }

            &_block {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-self: center;

                &_left-arrow {
                    padding-right: 5px;
                    margin-bottom: 15px;
                    cursor: pointer;
                    box-sizing: content-box;
                }

                &_right-arrow {
                    padding-left: 5px;
                    margin-bottom: 15px;
                    cursor: pointer;
                    box-sizing: content-box;
                }

                &_images-gallery {
                    display: flex;
                    flex-direction: row;
                    overflow-x: auto;
                    width: 190px;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    align-self: center;

                    &_enabled {
                        background-color: rgba(0, 0, 0, 0.8);
                        opacity: 0.7;
                        height: 81px;
                        width: 55px;
                        border-radius: 8px;
                        margin-left: 5px;
                        margin-right: 5px;
                        object-fit: contain;
                        cursor: pointer;
                    }

                    &_previews {
                        height: 81px;
                        width: 55px;
                        border-radius: 8px;
                        background-color: #D8D8D8;
                        margin-left: 5px;
                        margin-right: 5px;
                        object-fit: contain;
                        cursor: pointer;

                        &_active {
                            border: #08B273 3px solid;
                        }
                    }
                }
            }

            &_images-gallery::-webkit-scrollbar {
                display: none;
            }

            &_main-image {
                width: auto;
                height: auto;
                object-fit: contain;
                flex: 2;

                &_default {
                    visibility: hidden;
                }
            }

            &_zoom {
                display: flex;
                flex: 2;
                align-items: center;
                justify-content: center;
            }
        }

        &_right-part {
            width: 70px;
            position: absolute;
            right: 0;

            &_close-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                height: 33px;
                width: 33px;
                border: 1px solid #FFFEFE;
                opacity: 0.75;
                border-radius: 16px;
            }
        }

    }
}
