.shop-logo-container-wrapper {
    position: relative;
    width: 100%;
    padding: 4em 4em 1em 4em;

    .shop-logo-content-wrapper {
        display: block;
        padding-top: 30%;
        position: relative;
        width: 100%;
        height: 0;

        .shop-logo-content {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .shop-logo {
                object-fit: contain;
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0;
                width: 100%;
                max-width: 350px;
                transform: translateX(-50%);

                &.animate-onload {
                    opacity: 0;
                    will-change: opacity;
                    transition: .3s ease;
                }

                &.visible {
                    opacity: 1;
                }
            }
        }
    }
}
