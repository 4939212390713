.product-details-description-wrapper {
    justify-content: center;
    display: grid;
    grid-template-columns: 37% 58%;
    gap: 12px 7%;
    align-items: flex-start;
    grid-template-rows: max-content;

    .main-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        border-radius: var(--border-radius-xs);
        margin-top: 0;

        .main-image {
            width: 100%;
            cursor: pointer;
        }
    }

    &.hidden {
        visibility: hidden;
        height: 0;
    }

    .product-details {
        display: grid;
        gap: 12px;
        height: 100px;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;

        .product-title {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 20px;
        }

        .product-description-wrapper {
            height: 100%;
            cursor: pointer;
            overflow: hidden;
            position: relative;

            .more-button {
                font-size: 12px;
                position: absolute;
                bottom: 0;
                z-index: 1;
                text-decoration: underline;
                left: 0;
            }

            &:after {
                content: '';
                background-image: linear-gradient(to top, #fff 18px, rgba(255, 255, 255, 0) 100%);
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                height: 40px;
                opacity: 0;
            }

            &.overflow-background {
                &:after {
                    opacity: 1;
                }
            }

            .product-description {
                font-size: 12px;

                & * {
                    all: revert;
                    margin: 0;
                  
                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .image-gallery {
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, 52px);
        align-items: flex-start;
        grid-gap: 12px;
        grid-template-rows: 72px;
        grid-auto-flow: column;

        img {
            height: 72px;
            width: 52px;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .price-container {
        margin-bottom: 2px;
        color: var(--base-font-color);
        align-self: flex-end;

        h4 {
            font-size: 18px;
            font-weight: 600;
        }

        :nth-child(1) {
            color: var(--main-color);
            -webkit-text-fill-color: var(--main-color);
            margin-right: 8px;
        }

        :nth-child(2) {
            color: rgba(0, 0, 0, 0.4);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
            text-decoration: line-through;
        }
    }
}
