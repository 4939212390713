.mobile-product-details-item {
    border-top: 1px solid #F5F5F5;
    display: grid;
    grid-auto-flow: column;
    padding: 16px 0;
    gap: 16px;
    grid-template-columns: auto 1fr auto;
    
    .mobile-image-container {
        height: 76px;
        width: 76px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
        
        .empty-image {
            border-radius: 6px;
        }
    }

    .mobile-product-card-details-container {
        justify-self: flex-start;
        display: grid;
        grid-auto-flow: row;
        width: 100%;
        align-self: center;
        gap: 2px;

        .mobile-product-label {
            font-size: 14px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-align: start;
        }      
    }

    .mobile-add-button {
        align-self: center;
    }
}
