.full-screen-product-view-container {
    display: flex;
    flex-flow: row;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    right: 0;
    visibility: hidden;
    opacity: 0;

    &_close-btn {
        cursor: pointer;
    }

    &.visible {
        z-index: 1000;
        visibility: visible;
        opacity: 1;

        &.mobile {
            margin-top: -60px;
            height: 100vh;
        }
    }
}

.full-screen-product-gallery-container {
    flex: 1 1 70%;
}

// .presenting-text-mobile {
//     display: flex;
//     position: absolute;
//     z-index: 1;
//     top: 0px;
//     width: 100%;
//     align-items: center;
//     justify-content: center;
//     background-color: var(--main-color);
//     border: 1px solid white;
//     color: white;
//     -webkit-text-fill-color: white;
// }
