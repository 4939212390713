.video-loader-container {
    color: white;
    -webkit-text-fill-color: white;

    .video-loader {
        width: 80px;
        height: 80px;
        opacity: 0;
        transition: .2s opacity;
        will-change: opacity;

        &.visible {
            opacity: 1;
        }

        svg {
            width: 100%;
            height: 100%;
            transition: color .2s;
        }

        &.invert {
            svg {
                stroke: black;
            }
        }
    }
}

