.settings-dialog-container {
	position: fixed;
	left: 20px;
	right: 20px;
	background-color: #ffffff;
	z-index: 999999;
	border-radius: var(--border-radius-s);
	animation: showAudioVideoSettings .25s;
    will-change: opacity, transform;
	border-radius: 6px;

	&.standalone-mode {
		margin: -46px auto;
		max-width: 460px;
		left: 10px;
		right: 10px;
	}

	.tabs-container {
		display: flex;
		justify-content: center;
		flex-flow: row nowrap;
		border-bottom: 1px solid rgba(86, 86, 86, 0.16);
		font-size: 16px;
		font-weight: 500;
		width: 100%;

		.tab {
			flex: 1 1 20%;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			opacity: 0.4;
			will-change: opacity;
			position: relative;
			transition: 0.2s color, -webkit-text-fill-color, opacity;
			padding: 16px 8px 8px;

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 4px;
				right: 4px;
				opacity: 0;
				height: 2px;
				border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
				transform: translateY(2px);
				background-color: var(--main-color);
				will-change: opacity;
				transition: 0.2s opacity, transform;
			}

			.icon {
				display: inherit;
				vertical-align: middle;
				text-align: center;
				margin: 8px 16px;
			}

			&.selected {
				opacity: 1;
				color: var(--main-color);
				-webkit-text-fill-color: var(--main-color);

				&:before {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}

	.tab-content {
		display: flex;
		margin: 16px;

		.content {
			display: none;
			width: 100%;

			.container {
				display: grid;
				align-items: flex-end;
				grid-template-columns: 3fr 1.5fr;
				grid-gap: 16px 24px;
				margin: 16px 0;
				width: 100%;

				.section {
					display: flex;
					flex-flow: column;

					h5 {
						margin-bottom: 8px;
					}
				}

				.test-controls {
					display: flex;
					align-items: center;

					&.button {
						height: 40px;
					}

					audio {
						display: none;
						visibility: hidden;
					}

					button {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;

						span {
							margin-left: 8px;
							padding: 0;
						}
					}
				}
			}

			&.active {
				display: block;
			}
		}
	}

	.submit-button-container {
		display: grid;
		align-items: flex-end;
		grid-template-columns: 3fr 1.5fr;
		grid-gap: 16px 24px;
		margin: 16px;

		button {
			grid-column: 2;
		}
	}
}

video {
	width: 100%;
	height: auto;
}

@keyframes showAudioVideoSettings {
	from {
		opacity: 0;
		transform: translate3d(0, 10px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
