.mobile-sold-out-button {
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.32) !important;
    color: rgba(0, 0, 0, 0.32) !important;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.32) !important;
    background-color: #fff;
}


.mobile-sold-out-button,
.mobile-update-button,
.mobile-add-to-cart-button {
    margin: 0;
    width: 100%;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
    border-radius: 4px;
    height: 48px;
}

.mobile-update-button {
    margin-top: 8px;
}
