.intro-main-layout-wrapper {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    border-radius: var(--app-border-radius);

    .unmuted {
        width: 24px;
        height: 24px;
    }

    &.full-screen {
        box-shadow: none;
        border-radius: none;
    }

    .back-btn {
        animation: showArrows .2s;
    }

    .shop-logo-wrapper {
        opacity: 1;
        visibility: visible;
        transition: .25s;

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }

    .intro-main-layout {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        margin-bottom: 1.2em;

        .main-buttons-wrapper {
            margin-bottom: 2em;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            .main-buttons {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                width: 100%;
                margin-bottom: 1em;
            }

            .chat-link-container {  
                margin-bottom: 1em;              
                font-weight: 500;
                text-decoration: underline;
                color: #fff;
                -webkit-text-fill-color: #fff;
                line-height: 1.5em;
                min-height: 1.5em;
                transition: .3s;
                cursor: pointer;

                &.contrast {
                    color: currentColor;
                    -webkit-text-fill-color: currentColor;
                }
            }            
        }
    }
}

.sound-control-container {
    border-radius: 50%;
    height: 2em;
    width: 2em;
    position: absolute;
    bottom: 24px;
    left: 24px;
    overflow: hidden;
    z-index: 1;
    animation: showArrows .3s;

    .sound-control {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        color: #ffffff;
        -webkit-text-fill-color: #ffffff;

        &:before {
            content: '';
            position: absolute;
            background-color: #00000028;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

@keyframes showArrows {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 479px) {
    .intro-main-layout-wrapper {
        .intro-main-layout {
            .main-buttons-wrapper {
                .main-buttons {
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: 1fr 1fr;
                    gap: 1em;
                    margin-bottom: 1em;
                    padding: 0 1em;

                    &.single {
                        justify-items: center;

                        .schedule {
                            max-width: 184px;
                            width: 100%;
                            color: #ffffff;
                            -webkit-text-fill-color: #ffffff;
                            border: none;
                            background-color: var(--main-color);
                        }

                        .connect {
                            max-width: 184px;
                            width: 100%;
                        }
                    }

                    .connect {
                        padding: 10px;
                        width: auto;
                        height: auto;
                    }

                    .schedule {
                        padding: 10px;
                        width: auto;
                        height: auto;
                    }
                }

                .chat-link-container {
                    margin-bottom: 1.2em;

                    .chat-link {
                        display: flex;
                        text-align: center;
                        cursor: pointer;
                        text-decoration: underline;
                        align-self: center;
                        justify-content: center;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
