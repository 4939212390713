.chat-container {
	height: 70%;
	width: 70%;
	position: absolute;
	bottom: 0;
	left: 0;
	box-shadow: inset 0 -4px 0 0 var(--main-color);
	border-radius: var(--app-border-radius);
	z-index: 999;

	&.no-show {
		visibility: hidden;
	}
	&.tablet {
		width: 100%;
	}
}
