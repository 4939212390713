.form-container {
    display: block;
    padding: 0 3rem;
    width: 100%;

    &.name-field-only {
        height: calc(100% + 14px); // + gap cell related email field

        form {
            height: 100%;
            .form-header-title {
                align-self: flex-end;
            }

            .caazam-input {
                align-self: flex-start;
            }

            .actions-container {
                align-self: flex-end;
            }
        }
    }

    &.terms {
        form {
            grid-row-gap: 10px;

            .caazam-input {
                height: 48px;
            }

            .boutiq-checkbox-button-group .checkbox-option-label {
                align-items: flex-start;
            }

            .option-value {
                cursor: default;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: .9em;
                a {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }

    form {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 16px;
        align-items: center;
        width: 100%;

        .form-header-title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin: 1rem 0 0 .6ch;
            align-self: flex-start;
            overflow: hidden;
        }

        .actions-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .secondary-button {
                flex-grow: 1;
                text-align: center;
                text-decoration: underline;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .chat-link {
        display: flex;
        text-align: center;
        cursor: pointer;
        text-decoration: underline;
        align-self: center;
        justify-content: center;
        font-weight: 600;
    }

    .error-message-warning {
        height: 20px;
        margin-top: 10px;
    }

    .error-message {
        font-size: 1rem;
        height: 20px;
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 479px) {
    .form-container {
        padding: 0 1em;
    }
}
