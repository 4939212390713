.intro-welcome-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column;
    background-position: center;
    position: relative;

    &.sync-animation-background {
        &:before {
            animation: showBackground 2s .2s forwards;
            background-color: currentColor;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        will-change: opacity;
    }

    .welcome-background-image {
        opacity: 0;
        will-change: opacity;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 2s;
    }
}

@keyframes showBackground {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
