.intro-video-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    background: black;

    .progressbar-container {
        position: absolute;
        top: .8em;
        left: 2em;
        right: 2em;
        display: flex;
        flex-flow: row nowrap;
    }
}
