.mobile-tabs-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;

    .mobile-tabs-container {
        position: relative;
        display: flex;
        flex-direction: row;
		height: 100%;
		transition: .4s transform ease;
		transform: translateX(0);
		will-change: transform;

        .mobile-tab-wrapper {
            overflow: hidden;
            min-width: 100%;
            width: 100%;
        }
    }
}

.mobile-presentation-mode-view-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    -webkit-text-fill-color: #fff;
    background: var(--main-color);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
